import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, header: PropHeader=Header, footer: PropFooter=Footer, component: Component='main', ...props }) => {
  return (
    <>
      <PropHeader className='header'/>
      <Component className='content' {...props}>
        {children}
      </Component>
      <PropFooter className='footer' />
    </>
  );
};

export default Layout;
