import React from 'react';

import { Paper, Toolbar, Typography } from '@material-ui/core';

const Footer = props => (
  <Paper component='footer' {...props}>
    <Toolbar disableGutters>
      <Typography variant='caption' align='center' className='mx-auto my-0'>
        Alco Printing Services &copy; 2019
      </Typography>
    </Toolbar >
  </Paper>
);

export default Footer;
