import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'

import './index.css';

import firebase from 'firebase/app';
import "firebase/performance";
import perfMetrics from 'first-input-delay';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
apiKey: "AIzaSyA7RQa0RulBjyrLI6TH29Cf3sLdv-r8d9I",
authDomain: "sg-thng-firebase-alpha-01.firebaseapp.com",
databaseURL: "https://sg-thng-firebase-alpha-01.firebaseio.com",
projectId: "sg-thng-firebase-alpha-01",
storageBucket: "sg-thng-firebase-alpha-01.appspot.com",
messagingSenderId: "481867781726",
appId: "1:481867781726:web:84194d586656181c"
};

firebase.initializeApp(firebaseConfig);
const perf = firebase.performance();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
