import './App.css';

import React, { createContext, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { paths } from './components/Constants';

export const refContext = createContext();

const App = () => {
  const [ref, setRef] = useState({});

  return (
    <refContext.Provider value={{ ref, setRef }}>
      <BrowserRouter>
        <Switch>
          {paths.map(path => (
            <Route path={path.to} key={`route-${path.to}`} exact component={path.component} />
          ))}
        </Switch>
      </BrowserRouter>
    </refContext.Provider>
  )
};

export default App;
