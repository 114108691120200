import React, { useRef, useContext, useEffect } from 'react';
import { HomeOutlined, Phone, Email } from '@material-ui/icons';

import { refContext } from '../App';

import alcoBanner from '../assets/images/About_ alcosmall.jpg';
import p1 from '../assets/images/products/Home_Products_01.png';
import p2 from '../assets/images/products/Home_Products_02.png';
import p3 from '../assets/images/products/Home_Products_03.png';
import p4 from '../assets/images/products/Home_Products_04.png';
import p5 from '../assets/images/products/Home_Products_05.png';
import p6 from '../assets/images/products/Home_Products_06.png';
import p7 from '../assets/images/products/Home_Products_07.png';
import p8 from '../assets/images/products/Home_Products_08.png';

import { CardMedia, createStyles, withStyles, Divider, CardContent, GridList, GridListTile, Card, Typography, withWidth } from '@material-ui/core';
import Layout from './Layout';

const HomeStyle = createStyles({
  cardMedia: {
    width: '100%',
    height: 'auto'
  },
  productMedia: {
    width: '70%',
    height: 'auto'
  },
})

const HomeBase = ({ width, classes, ...props }) => {
  const { ref, setRef } = useContext(refContext);
  const prodRef = useRef();
  const contRef = useRef();

  useEffect(() => {
    if (ref.contact !== contRef) setRef({...ref, contact: contRef});
  }, [ref, setRef, contRef]);

  useEffect(() => {
    if (ref.product !== prodRef) setRef({...ref, product: prodRef});
  }, [ref, setRef, prodRef]);

  const cols = width === 'xs' ? 3
  : width === 'sm' ? 4
  : width === 'md' ? 5
  : 6;

  return (
    <Layout>
      <Card id='home' className='mb-3'>
        <CardMedia className={classes.cardMedia} component='img' src={alcoBanner} title="Alco Printing Services"/>
      </Card>
      <Card className='mb-3' ref={prodRef}>
        <CardContent>
          <SectionHeader id='our-products' title="Our Products" />
          <GridList cellHeight='auto' cols={cols}>
            {products.map(product => (
              <GridListTile key={product.src} cols={1} rows={1} >
                <Card elevation={0} className='m-3' style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                  <CardMedia className={`${classes.productMedia} mx-auto mb-2`} component='img' src={product.src} title={product.label} />
                  <Typography variant='body1' align='center'>{product.label}</Typography>
                </Card>
              </GridListTile>
            ))}
          </GridList>
        </CardContent>
      </Card>
      <Card className='mb-3' ref={contRef}>
        <CardContent>
          <SectionHeader id='contact-us' title="Contact Us" />
          <div className='pl-3 mt-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'stretch' }}>
            <div className='pr-4 border-right mr-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: 355 }}>
              <div className='mb-4 mt-3' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <HomeOutlined className='mr-4' />
                <address>
                  <Typography>Blk 52 Chin Swee Road #02-45</Typography>
                  <Typography>Singapore 160052</Typography>
                </address>
              </div>
              <a className='mb-4' href='tel:+6567325971' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Phone className='mr-4' />
                <Typography>+65 6732 5971</Typography>
              </a>
              <a className='mb-3' href='mailto:alcoprinting@gmail.com' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <Email className='mr-4' />
                <Typography>alcoprinting@gmail.com</Typography>
              </a>
            </div>
            <Card
              component='iframe'
              title="Alco Printing Services" 
              className="flex-grow mr-1"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.811554001323!2d103.8393084149822!3d1.2871659621316347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1975b63ec647%3A0x566e96a7d57891a0!2sAlco+Printing+Services!5e0!3m2!1sen!2ssg!4v1564159108636!5m2!1sen!2ssg" 
              frameBorder={0}
              style={{ borderRadius: 10, width: '100%', height: 200 }} 
            />
          </div>
        </CardContent>
      </Card>
    </Layout>
  );
};

const SectionHeader = ({ title }) => (
  <div className='pl-2 my-4' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
    <Typography variant='title' align='center'>{title}</Typography>
    <Divider className='ml-3 mr-1' style={{ flexGrow: 1 }} />
  </div>
);

const products = [
  { src: p7, label: 'Rubber Stamps & Common Seals'},
  { src: p2, label: 'Digital & Offset Printing'},
  { src: p3, label: 'Large Format Printing'},
  { src: p1, label: 'Namecard'},
  { src: p4, label: 'Stickers'},
  { src: p5, label: 'Greeting Cards'},
  { src: p6, label: 'Plastic Cards'},
  { src: p8, label: 'Other Services'},
]

const Home = withWidth()(withStyles(HomeStyle)(HomeBase));

export const paths = [
  {label: "",/*'Home', */to: '', /*icon: <HomeOutlined />,*/ component: Home},
  // // {label: 'About', to: '/about/', icon: <PeopleOutlineTwoTone />, component: LayoutCard},
  // {label: 'Our Products', to: 'product', icon: <StoreOutlined />, component: LayoutCard},
  // {label: 'Contact Us', to: 'contact', icon: <LocationOnOutlined />, component: LayoutCard},
];

