import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { withMobileDialog, AppBar, Toolbar, Button, IconButton, createStyles, withStyles } from "@material-ui/core";

import logo from '../assets/images/logo.png';

import { paths } from './Constants';
import { refContext } from '../App';

const headerStyle = createStyles({ logo: { height: '3.5em' }});

const scrollToRef = (ref) => window.scrollTo(0, ref ? ref.current.offsetTop : 0);
const HeaderBase = ({ fullScreen, classes, match, ...props }) => {
  const { ref } = useContext(refContext);
  return (
    <AppBar color='inherit' position='sticky' elevation={1}>
      <Toolbar>
        <Button component={NavLink} exact to={paths[0].to} size='small'>
          <img id='nav-logo' className={classes.logo}
            src={logo} alt='Alco Printing Services'/>
        </Button>
        <nav className='ml-auto'>
          {paths.map(path => {
            let props = {
              /* component: NavLink, */
              key: `${fullScreen}-${path.label}`,
              /* exact: true, */
              /* to: path.to, */
              color: match.url === path.to ? 'primary' : undefined,
              onClick: () => scrollToRef(ref[path.to]),
            };

            return fullScreen ? (
              <IconButton className='p-2' {...props}>{path.icon}</IconButton>
            ): (
              <Button {...props}>{path.icon}{` ${path.label}`}</Button>
            );
          })}
        </nav>
      </Toolbar>
    </AppBar>
  );
}

const breakpoint = { breakpoint: 'xs' };
const Header = withStyles(headerStyle)(withMobileDialog(breakpoint)(withRouter(HeaderBase)));

export default Header;
